import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../../pages/Home";
import AddProductPage from "../../pages/Product/AddProductPage";
import ProductListPage from "../../pages/Product/ListPage";
import Header from "../../components/Header";
import EditProductPage from "../../pages/Product/EditProductPage";

export const PrivateRoutes = () => {
  return (
    <BrowserRouter>
      <Header>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<ProductListPage />} />
          <Route path="/products/new" element={<AddProductPage />} />
          <Route path="/products/:id" element={<EditProductPage />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
        </Routes>
      </Header>
    </BrowserRouter>
  );
};

