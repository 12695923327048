import { useEffect, useState, useMemo } from 'react';
import { useTable, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import ProductService from '../../services/Product';

interface Product {
  id: string;
  code?: number;
  costPrice: number;
  name: string;
  category: string;
  quantity: number;
}

type MyColumn = Column<Product> & { accessor: keyof Product | ((row: Product) => string); };

export default function ProductListPage() {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    async function fetchProducts() {
      const fetchedProducts = await ProductService.getProducts();
      setProducts(fetchedProducts);
    }
    fetchProducts();
  }, []);

  const columns: MyColumn[] = useMemo(
    () => [
      {
        Header: 'Código',
        accessor: 'code',
        Cell: ({ value }: any) => <b>{String(value).padStart(5, '0')}</b>,
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Categ.',
        accessor: 'category',
      },
      {
        Header: 'Qtd',
        accessor: 'quantity',
      },
      {
        Header: 'Preço Final',
        accessor: 'costPrice',
        Cell: ({ row }: any) => {
          const { costPrice, packagingPrice, margin } = row.original;
          const finalPrice = ((costPrice * (1 + margin/100)) + packagingPrice);
          return <b>R$ {finalPrice.toFixed(2)}</b>;
        },
      },
      {
        Header: 'Editar',
        accessor: 'id',
        Cell: ({ value }: any) => <Link to={`/products/${value}`}>Editar</Link>,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: products,
  });

  return (
    <div>
      <Button href="/products/new">Adicionar Novo Produto</Button>
      <Table className="mt-4" {...getTableProps()} >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.length ? (rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>Nenhum produto Cadastrado</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  );
};

