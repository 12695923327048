import { firestore as db } from "../../firebase";
import { collection, doc, getDocs, getDoc, updateDoc, deleteDoc, runTransaction } from "firebase/firestore";

export interface Product {
  id: string;
  name: string;
  category: string;
  subcategory: string;
  rawMaterial: string;
  supplier: string;
  costPrice: number;
  codeSupplier: string;
  color: string;
  brand?: string;
  quantity: number;
  code?: number;
  createAt?: Date;
}

const ProductService = {
  getProducts: async (): Promise<Product[]> => {
    const querySnapshot = await getDocs(collection(db, "products"));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Product));
  },
  getFirestoreProduct: async (id: string): Promise<Product | null> => {
    const querySnapshot = await getDoc(doc(db, "products", id));
    return querySnapshot.data() as Product;
  },
  updateFirestoreProduct: async (id: string, product: any): Promise<void> => {
    const productRef = doc(db, "products", id);
    await updateDoc(productRef, product);
  },
  deleteFirestoreProduct: async (id: string): Promise<void> => {
    const productRef = doc(db, "products", id);
    await deleteDoc(productRef);
  },
  addProduct: async (product: Product | any): Promise<Product> => {
    try {
      let newCode = 0;
      await runTransaction(db, async (transaction) => {
        const counterRef = await doc(db, "counters", "product");
        const counterDoc = await transaction.get(counterRef);
        if (!counterDoc.exists()) {
          await transaction.set(counterRef, { code: 1 });
          newCode = 1;
        } else {
          newCode = (counterDoc.data().code || 0) + 1
          await transaction.update(counterRef, { code: newCode });
        }

        product.code = newCode;
        product.createAt = new Date();

        const docRef = await doc(collection(db, "products"));
        await transaction.set(docRef, product)
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    return product;

  }
};

export default ProductService;
