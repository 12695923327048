import { Button } from 'react-bootstrap';
import { signProvider } from '../../firebase';

export default function Login() {

  const handleGoogleLogin = async () => {
    await signProvider();
  }

  return (
    <div className="login-container">
      <h1>Bem-vinda</h1>
      <Button variant="primary" onClick={handleGoogleLogin}>
        Login with Google
      </Button>
    </div>);
};

