
import React, { createContext, useCallback, useEffect, useState } from "react";
import { signProvider, UserAuth, auth } from "../../firebase";


interface AuthProviderProps {
  children: React.ReactNode;
}

export interface AuthContextData {
  user: UserAuth | null;
  Login(): void;
  Logout(): void;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export default function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserAuth | null>(null);
  const [loading, setLoading] = useState(true);

  const Login = useCallback(async () => {
    setLoading(true);
    try {
      const user = (await signProvider()) as UserAuth;

      setUser(user);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const Logout = useCallback(async () => {
    setLoading(true);
    try {
      setUser(null);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const GetCurrentUser = useCallback(async () => {
    setLoading(true);
    if (!user) {
      auth.onAuthStateChanged((currentUser) => {
        setUser(currentUser);
        setLoading(false);
      })
    }
  }, [user]);

  useEffect((): any => {
    if (!user) GetCurrentUser();
  }, [GetCurrentUser, user]);

  return (
    <AuthContext.Provider value={{ user, Login, Logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

