import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import ProductService from '../../services/Product';
import { schema, inputsGroup } from './AddProductPage';
import Modal from 'react-bootstrap/Modal';

interface IFormInput {
  name: string;
  code: number;
  category: string;
  subcategory: string;
  rawMaterial: string;
  supplier: string;  
  codeSupplier: string;
  color: string;
  quantity: number;
  costPrice: number;
  packagingPrice: number;
  margin: number;
  brand?: string;
}

export default function EditProductPage() {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<IFormInput | null>(null);
  const { control, handleSubmit, reset, formState: { errors } } = useForm<IFormInput | any>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function loadProduct() {
      const productData = await ProductService.getFirestoreProduct(id || "") as unknown as IFormInput;
      productData.margin *= 100;
      productData.costPrice *= 100;
      productData.packagingPrice *= 100;
      setProduct(productData);
      reset(productData);
    }

    loadProduct();
  }, [id, reset]);

  const onUpdate: SubmitHandler<IFormInput> = async data => {
    await ProductService.updateFirestoreProduct(id || "", data);
    navigate('/products');
  };

  const onDelete = async () => {

    await ProductService.deleteFirestoreProduct(id || "");
    navigate('/products');
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Exluir</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja realmente excluir o Produto?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onDelete}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
      {product ? (
        <div>
                <h3 className="mb-3">Produto: {String(product.code).padStart(5, '0')} - {product.name} </h3>

        <Form>
          <Row>
            <Col>
              {inputsGroup(control, errors)}
            </Col>
          </Row>
          <Row className="mt-3">
            <Button variant="primary" onClick={handleSubmit(onUpdate)}>
              Atualizar Produto
            </Button><br />
          </Row>
          <Row className="mt-1">
            <Button variant="danger" onClick={handleShow}>
              Remover Produto
            </Button>
          </Row>
        </Form>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </Container>
  );
};

