import { useContext } from "react";
import { AuthContext, AuthContextData } from "../context/auth";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";

export const useAuth = (): AuthContextData => {
  const context = useContext<AuthContextData>(AuthContext);

  return context;
};
export const Routes = () => {
  const { user, loading } = useAuth();

  console.log(user);
  if (loading) return <div>carregando</div>;

  return user ? <PrivateRoutes /> : <PublicRoutes />;
};





