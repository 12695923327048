import React, { useState } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface HeaderProps {
  children: React.ReactNode;
}

export default function Header({ children }: HeaderProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleNav = () => setExpanded(!expanded);
  const collapseNav = () => setExpanded(false);

  return (
    <div>
      <Row>
        <Col>
          <Navbar bg="light" expand="lg" expanded={expanded}>
            <Container>
              <Navbar.Brand href="#home">Página Principal</Navbar.Brand>
              <Navbar.Toggle onClick={toggleNav} aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Link className="nav-link" to="/customers" onClick={collapseNav}>Clientes</Link>
                  <Link className="nav-link" to="/products" onClick={collapseNav}>Produtos</Link>
                  <Link className="nav-link" to="/sells" onClick={collapseNav}>Vendas</Link>
                  <Link className="nav-link" to="/logout" onClick={collapseNav}>Logout</Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
      <Row className="mx-auto my-3">
        <Col>
          {children}
        </Col>
      </Row>
    </div>
  );
}
