
import { Container } from 'react-bootstrap';
import { Routes } from './routes/index';
import AuthProvider from './context/auth';

function App() {
  return (
    <AuthProvider>
      <Container fluid>
        <Routes />
      </Container>
    </AuthProvider>
  );
}

export default App;
