import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { config } from "../common/config";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = config.firebase;

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const firestore = getFirestore(firebase);

const auth = getAuth();

const signProvider = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    return user;
  } catch (err) {
    console.log(err);
  }
}

export interface UserAuth {
  uid: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  emailVerified: boolean;
  isAnonymous: boolean;
}

export { firebase, signProvider, auth, firestore };
