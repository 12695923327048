import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Form, Row, Col, Container, Modal } from 'react-bootstrap';
import ProductService, { Product } from '../../services/Product';
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function formatPriceEvent(e: any, onChange: any) {
  onChange(formatPrice(e.target.value));
}

function formatPrice(value: string): string {
  const unformattedValue = value.toString().replace(/[^\d,]/g, '').replace(',', '.');

  let numericValue = unformattedValue ? parseFloat(unformattedValue) / 100 : 0.00;
  return `R$ ${numericValue.toFixed(2)}`;
}

function formartMargin(value: string): string {
  const unformattedValue = value.toString().replace(/[^\d,]/g, '').replace(',', '.');

  let numericValue = unformattedValue ? parseFloat(unformattedValue) / 100 : 0.00;
  return `${numericValue.toFixed(2)} %`;

}

function formatPercentEvent(e: any, onChange: any) {
  onChange(formartMargin(e.target.value));
}

function unformatPrice(_: any, value: string): number | string {
  const numericValue = value.toString().replace(/[^\d,]/g, '').replace(',', '.');
  const floatValue = parseFloat(numericValue);
  return isNaN(floatValue) ? value : floatValue / 100;
}
interface IFormInput {
  name: string;
  category: string;
  subcategory: string;
  supplier: string;
  codeSupplier: string;
  quantity: number;
  costPrice: number;
  packagingPrice: number;
  margin: number;
  brand?: string;
  rawMaterial: string;
}

export const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório"),
  rawMaterial: yup.string().required("Nome é obrigatório"),
  category: yup.string().required("Categoria é obrigatória"),
  supplier: yup.string().required("Fornecedor é obrigatório"),
  subcategory: yup.string().required("Sub-Categoria é obrigatória"),
  quantity: yup.number().typeError("Quantidade precisa ser um número").required("Quantidade é obrigatória"),
  costPrice: yup.number().typeError("Preço de Custo precisa ser um número").transform(unformatPrice).required("Preço de Custo é obrigatório").min(0.01, "O Preço não pode ser R$ 0.00"),
  packagingPrice: yup.number().transform(unformatPrice).typeError("Preço da Embalagem precisa ser um número").required("Preço da Embalagem é obrigatório"),
  margin: yup.number()
    .typeError("A margem precisa ser um número")
    .transform(unformatPrice)
    .required("A margem é obrigatória")
    .min(0, "A margem não pode ser negativa")
    .max(500, "A margem não pode ser maior que 500"),
  brand: yup.string().default(""),
});

export const categories = ['Brinco', 'Piercing', 'Gargantilha', 'Pulseria', 'Tornozeleira', 'Pingente', 'Anel', 'Outro'];
export const subcategories = ['Unisex', 'Feminino', 'Masculino', 'Infantil'];
export const rawMaterials = ['Prata', 'Ouro', 'Pedras', 'Outro'];

export const inputsGroup = (control: any, errors: any) => (
  <div>
    <h4 className="mb-3">Informações Básicas</h4>
    <hr />
    <Form.Group as={Row} className="mb-3" controlId="formProductName">
      <Form.Label column sm={1} >Nome</Form.Label>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => <Col sm={11}> <Form.Control placeholder="Nome do Produto" isInvalid={!!errors.name} {...field} /> </Col>}
      />
      <Form.Control.Feedback type="invalid">
        {errors.name?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductCategory">
      <Form.Label column sm={1}>Categoria</Form.Label>
      <Controller
        name="category"
        control={control}
        defaultValue={categories[0]}
        render={({ field }) => (
          <Col sm={11}> <Form.Control as="select" isInvalid={!!errors.category} {...field}>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </Form.Control></Col>
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errors.category?.message}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group as={Row} className="mb-3" controlId="formProductSubCategory">
      <Form.Label column sm={1}>Subcategoria</Form.Label>
      <Controller
        name="subcategory"
        control={control}
        defaultValue={subcategories[0]}
        render={({ field }) => (
          <Col sm={11}> <Form.Control as="select" isInvalid={!!errors.subcategory} {...field}>
            {subcategories.map((subcategory, index) => (
              <option key={index} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </Form.Control></Col>
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errors.subcategory?.message}
      </Form.Control.Feedback>
    </Form.Group>


    <Form.Group as={Row} className="mb-3" controlId="formProductBrand">
      <Form.Label column sm={1} >Marca</Form.Label>
      <Controller
        name="brand"
        control={control}
        defaultValue=""
        render={({ field }) => <Col sm={11}> <Form.Control placeholder="Marca do Produto (Caso houver)" isInvalid={!!errors.brand} {...field} /> </Col>}
      />
      <Form.Control.Feedback type="invalid">
        {errors.brand?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <h4 className="mb-3">Detalhes do Produto</h4>
    <hr />

    <Form.Group as={Row} className="mb-3" controlId="formProductRawMaterial">
      <Form.Label column sm={1}>Material</Form.Label>
      <Controller
        name="rawMaterial"
        control={control}
        defaultValue={rawMaterials[0]}
        render={({ field }) => (
          <Col sm={11}> <Form.Control as="select" isInvalid={!!errors.rawMaterial} {...field}>
            {rawMaterials.map((rawMaterial, index) => (
              <option key={index} value={rawMaterial}>
                {rawMaterial}
              </option>
            ))}
          </Form.Control></Col>
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errors.rawMaterial?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductCor">
      <Form.Label column sm={1} >Cor</Form.Label>
      <Controller
        name="color"
        control={control}
        defaultValue=""
        render={({ field }) => <Col sm={11}> <Form.Control placeholder="Cor do Produto (Caso houver)" isInvalid={!!errors.color} {...field} /> </Col>}
      />
      <Form.Control.Feedback type="invalid">
        {errors.color?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <h4 className="mb-3">Estoque e Logistica</h4>
    <hr />

    <Form.Group as={Row} className="mb-3" controlId="formProductSupplier">
      <Form.Label column sm={1} >Fornecedor</Form.Label>
      <Controller
        name="supplier"
        control={control}
        defaultValue=""
        render={({ field }) => <Col sm={11}> <Form.Control placeholder="Fornecedor" isInvalid={!!errors.supplier} {...field} /> </Col>}
      />
      <Form.Control.Feedback type="invalid">
        {errors.supplier?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductSupplierCode">
      <Form.Label column sm={1} >Cód. Fornecedor</Form.Label>
      <Controller
        name="codeSupplier"
        control={control}
        defaultValue=""
        render={({ field }) => <Col sm={11}> <Form.Control placeholder="Código Interno do Fornecedor" isInvalid={!!errors.codeSupplier} {...field} /> </Col>}
      />
      <Form.Control.Feedback type="invalid">
        {errors.codeSupplier?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductQuantity">
      <Form.Label column sm={1}>Quantidade</Form.Label>
      <Controller
        name="quantity"
        control={control}
        defaultValue={1}
        render={({ field }) => (
          <Col sm={11}>
            <Form.Control
              type="number"
              isInvalid={!!errors.quantity}
              {...field}
            /></Col>
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errors.quantity?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductCostPrice">
      <Form.Label column sm={1}>Custo(R$) </Form.Label>
      <Controller
        name="costPrice"
        control={control}
        defaultValue={'R$ 0.00'}
        render={({ field: { onChange, value, name, ref } }) => (
          <Col sm={11}>
            <Form.Control
              type="text"
              isInvalid={!!errors.costPrice}
              onChange={(e) => formatPriceEvent(e, onChange)}
              value={formatPrice(value)}
              name={name}
              ref={ref}
            /></Col>
        )
        }
      />
      <Form.Control.Feedback type="invalid">
        {errors.costPrice?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductPackagingPrice">
      <Form.Label column sm={1}>Embal.(R$)</Form.Label>
      <Controller
        name="packagingPrice"
        control={control}
        defaultValue={'R$ 0.00'}
        render={({ field: { onChange, value, name, ref } }) => (
          <Col sm={11}>
            <Form.Control
              type="text"
              isInvalid={!!errors.packagingPrice}
              onChange={(e) => formatPriceEvent(e, onChange)}
              value={formatPrice(value)}
              name={name}
              ref={ref}
            /></Col>)}
      />
      <Form.Control.Feedback type="invalid">
        {errors.packagingPrice?.message}
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Row} className="mb-3" controlId="formProductMargin">
      <Form.Label column sm={1}>Margem</Form.Label>
      <Controller
        name="margin"
        control={control}
        defaultValue={'0 %'}
        render={({ field: { onChange, value, name, ref } }) => (
          <Col sm={11}>
            <Form.Control
              type="text"
              isInvalid={!!errors.margin}
              onChange={(e) => formatPercentEvent(e, onChange)}
              value={formartMargin(value)}
              name={name}
              ref={ref}
            /></Col>
        )} />
      <Form.Control.Feedback type="invalid">
        {errors.margin?.message}
      </Form.Control.Feedback>
    </Form.Group>


  </div>
);

export default function AddProductPage() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [product, setProduct] = useState<Product>();

  const handleClose = () => {
    setShow(false);
    navigate('/products');
  };

  const handleShow = () => setShow(true);


  const { control, handleSubmit, formState: { errors } } = useForm<IFormInput | any>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const newProduct = await ProductService.addProduct(data);
    setProduct(newProduct);
    handleShow();
  };

  return (
    <Container fluid="3">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Produto!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Código: <b>{String(product?.code).padStart(5, '0')}</b> Adicionado com Sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <h3 className="mb-3">Cadastro de Produto</h3>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {inputsGroup(control, errors)}
        <Row className="mt-2">
          <Button variant="primary" type="submit">
            Adicionar Produto
          </Button>
        </Row>
      </Form>
    </Container>
  );
};
